<!--
 * @Description: 商城管理
 * @Autor: zhangzhang
 * @Date: 2021-06-10 15:46:39
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-04 08:53:34
-->
<template>
  <div class="manage">
    <!-- 头部菜单 -->
    <div class="manage-head">
      <div class="manage-head-content">
        <div @click="$router.push('/choice')">⏎返回</div>
        <el-button size="small f-white bg-theme" @click="create">+ 创建新项目</el-button>
      </div>
    </div>

    <!-- 内容 -->
    <div class="manage-body">
      <h2 class="manage-body-title">
        我的项目
        <span>myProject</span>
      </h2>
      <ul v-if="projectList.length != 0" class="list">
        <li class="list-item" v-for="(item, num) in projectList" :key="item.id">
          <div class="list-item-content">
            <img src="../assets/image/title.gif" class="w100 mt10 radius" />
            <div class="w100 h100 mt10" :style="'background: url(' + item.cover + ') no-repeat;background-size: cover;border-radius:10px;border:1px solid #f5f5f5;'"></div>
            <div class="mt25 f20">{{ item.name }}</div>
            <div :class="shijianc(item.endTime, 'day') == '已过期' ? 'list-item-content-type  red' : 'list-item-content-type '">到期：{{ item.endTime ? shijianc(item.endTime) : '永久' }}（{{ item.endTime ? shijianc(item.endTime, 'day') : '无限制' }}）</div>
            <div class="list-item-content-btn">
              <span @click="update(item, num)">修改</span>
              <span class="bor" @click.stop="del(item._id, num, item)">删除</span>
            </div>
          </div>
        </li>
      </ul>
      <el-empty v-if="projectList.length == 0" class="mt80">
        <template slot="description">
          <span class="f13 f-grey">{{ `快去创建官网网站吧~` }}</span>
        </template>
      </el-empty>
    </div>

    <!-- 模版 -->
    <div class="manage-body">
      <h2 class="manage-body-title">
        项目模板
        <span>projectModel</span>
      </h2>
      <ul v-if="template.length != 0" class="list">
        <li class="list-item" v-for="(item, num) of template" :key="item.id">
          <div class="list-item-content">
            <img src="../assets/image/m.gif" class="w100 mt10 radius" />
            <div class="w100 h100 mt10" :style="'background: url(' + item.cover + ') no-repeat;background-size: cover;border-radius:10px;border:1px solid #f5f5f5;'"></div>
            <div class="mt25 f20">{{ item.name }}</div>
            <div class="list-item-type">项目模板</div>
            <div class="list-item-content-btn">
              <span @click="create">选用此模板</span>
            </div>
          </div>
        </li>
      </ul>
      <el-empty v-if="template.length == 0" class="mt80">
        <template slot="description">
          <span class="f13 f-grey">{{ `快去创建模版吧~` }}</span>
        </template>
      </el-empty>
    </div>
    <create-dialog ref="create"></create-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { getProjectList, delProject } from '@/api/project'
import CreateDialog from '@/components/CreateDialog'
import http from '@/utils/cloud'

export default {
  name: 'mall-managet',

  components: {
    CreateDialog
  },

  created() {
    this.dropProject()
    this.getMallList()
    this.getModelList()
  },

  data() {
    return {
      show: false,
      form: {},
      projectList: [],
      template: []
    }
  },

  computed: {
    ...mapGetters(['project', 'userInfo'])
  },

  watch: {
    show: {
      immediate: true,
      handler() {
        if (!this.show) this.form = {}
      }
    }
  },

  methods: {
    ...mapMutations(['dropProject', 'setProject']),

    // 年-月-日
    shijianc(time, value) {
      var date = new Date(time)
      var nowTime = +new Date()
      if (value == 'day') {
        if (time - nowTime < 0) {
          return '已过期'
        } else {
          return '剩' + (Math.floor((time - nowTime) / (1000 * 3600 * 24)) + 1) + '天'
        }
      }
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
      var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return Y + M + D
    },
    // 获取列表
    async getMallList() {
      const res = await http.GET('/jw-admin', {
        module: 'project',
        operation: 'getProjectList'
      })
      this.projectList = res

      // 自动进入第一个项目
      // if (this.list[0].list.length > 0 && true) {
      //   this.setProject(this.list[0].list[0])
      //   this.$router.push({ name: 'category' })
      // }
    },

    // 获取模版列表
    async getModelList() {
      const res = await http.GET('/jw-admin', {
        module: 'project',
        operation: 'getModelListAll',
        recycle: 0
      })
      this.template = res
    },

    // 创建商城
    create() {
      localStorage.setItem('routerType', 'user')
      this.$refs['create'].open('user')
    },

    // 编辑商城
    update(project, index) {
      this.setProject(project)
      localStorage.setItem('routerType', 'user')
      this.$router.push({ name: 'homeData' })
    },

    // 删除商城
    del(id, index, item) {
      if (process.env.NODE_ENV == 'production') {
        if (item.del == 2) {
          this.$message.error('演示项目不可删除')
          return
        }
      }

      this.$confirm('删除项目后将不可恢复, 是否继续?', '删除项目', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        http
          .POST('/jw-admin', {
            module: 'project',
            operation: 'deleteCategory',
            id: id
          })
          .then(response => {
            this.getMallList()
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.manage {
  position: relative;

  // 隐藏滚动条
  &::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }

  .manage-head {
    position: fixed;
    top: 0;
    left: 0;
    height: 50px;
    width: 100%;
    background: #222222;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    z-index: 10;

    .manage-head-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1260px;
      height: 100%;
      margin: 0 auto;
      line-height: 50px;
      text-align: right;
      color: #ffffff;

      > div {
        cursor: pointer;
      }
    }
  }

  .manage-body {
    width: 1300px;
    margin: 100px auto;

    .manage-body-title {
      font-size: 18px;
      color: #1b1c26;
      margin-bottom: 20px;
      padding: 0 15px;
      font-weight: 700;

      span {
        color: #ced1d6;
      }
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      .list-item {
        .list-item-content {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 220px;
          margin: 10px 15px 50px;
          padding-top: 20px;
          border-radius: 10px;
          background-image: linear-gradient(-50deg, #edf1f7, #ebedf4);
          box-shadow: 0 5px 10px 0 rgba(2, 31, 65, 0.05);
          cursor: pointer;
          transition: all 0.2s;

          img {
            border-radius: 5px;
          }

          &:hover {
            box-shadow: 0 20px 40px 0 rgba(15, 48, 85, 0.1);
            margin-top: 5px;
          }
          .red {
            color: red !important;
          }
          .list-item-content-type {
            position: relative;
            margin-top: 15px;
            font-size: 14px;
            color: #61616a;
            line-height: 24px;
          }
          .list-item-type {
            position: relative;
            margin-top: 15px;
            font-size: 14px;
            color: #61616a;
            line-height: 24px;

            &::after {
              content: '';
              position: absolute;
              top: 50%;
              right: -70px;
              width: 100%;
              height: 1px;
              background-color: #e1e0e6;
            }

            &::before {
              content: '';
              position: absolute;
              top: 50%;
              left: -70px;
              width: 100%;
              height: 1px;
              background-color: #e1e0e6;
            }
          }

          .list-item-content-btn {
            margin: 50px auto 25px;
            width: 180px;
            border-radius: 100px;
            background-color: hsla(0, 0%, 100%, 0.4);
            background: #155bd4;
            display: flex;
            align-items: center;
            height: 40px;
            justify-content: center;

            span {
              display: inline-block;
              width: 50%;
              height: 100%;
              font-size: 12px;
              color: #fff;
              text-align: center;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .bor {
              border-left: 1px solid #fff;
            }
          }
        }
      }
    }
  }
}
</style>
